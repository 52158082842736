import type { GraphQLResponse } from "graphql-request";
import { useState } from "react";
import { redirect } from "react-router";
import { Form } from "react-router";
import Button from "~/components/button";
import Heading from "~/components/heading";
import Link from "~/components/link";
import poster from "~/images/poster.jpg";
import { loginAccessUser } from "~/models/access-user.server";
import { createUserSession, getUser, getUserId } from "~/session.server";
import { handleError } from "~/store.server";
import { Errors } from "~/utils/forms";
import type { Route } from "./+types/login";

export const loader = async ({ request }: Route.LoaderArgs) => {
  const userId = await getUserId(request);
  if (userId) {
    await getUser(request);
    return redirect("/projects");
  }

  return {};
};

export const action = async ({ request }: Route.ActionArgs) => {
  try {
    const params = new URL(request.url).searchParams;
    const fd = await request.formData();
    const login = fd.get("login") as string;
    const password = fd.get("password") as string;
    const user = await loginAccessUser(request, login, password);

    return createUserSession({
      request,
      userId: user.loginAccessUser!.accessUser!.id,
      token: user.loginAccessUser!.token!,
      email: user.loginAccessUser!.accessUser!.login!,
      redirectTo: params.get("return") || "/"
    });
  } catch (error) {
    return handleError(error);
  }
};

export const meta = () => [{ title: "Login" }];

export default function Login({ actionData }: Route.ComponentProps) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div>
      <Heading title="Login" />
      <div className="row">
        <div className="col-md-6">
          <div style={{ maxWidth: 500 }}>
            {actionData && "errors" in actionData && (
              <Errors errors={(actionData as GraphQLResponse).errors} />
            )}
            <Form method="post">
              <div className="form-group">
                <label htmlFor="login">Email Address</label>
                <input
                  name="login"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  className="form-control"
                  autoFocus
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="form-control"
                />
              </div>
              <Button
                mode="primary"
                type="submit"
                disabled={!login || !password}
              >
                Login
              </Button>
            </Form>
          </div>
          <hr />
          <p>
            Don&apos;t have an account?{" "}
            <Link to="/register">Sign up for Free!</Link>
          </p>
          <p>
            Forgot your password?{" "}
            <Link to="/reset-password">Reset your Password</Link>
          </p>
        </div>
        <div className="col-md-6">
          <div id="video" className="img-thumbnail ca">
            <video
              className="img-responsive"
              id="movie"
              controls
              preload="none"
              poster={poster}
              onClick={(e) => {
                const vid = e.target as HTMLVideoElement;
                if (vid.paused || vid.ended) {
                  vid.play();
                } else {
                  vid.pause();
                }
                e.preventDefault();
              }}
              src="https://pfcs-misc.s3.amazonaws.com/2016-01-08_Client_Access.mp4"
            />
          </div>
          <p>
            Watch a quick overview of signing up for and using Client Access
          </p>
        </div>
      </div>
    </div>
  );
}
